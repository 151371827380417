<template>
  <div
    class="category_slider"
    v-if="BrandSlider.slides"
  >
    <h3>
      {{ $t("read_more_about_slider") }}
    </h3>
    <VueSlickCarousel
      v-if="BrandSlider.slides.length"
      :arrows="true"
      :dots="false"
      :slidesToShow="5"
      :slidesToScroll="1"
      :autoplay="false"
      :center="false"
      :responsive="[
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ]"
    >
      <div
        class="cms-block-content"
        v-for="(slide, index) in BrandSlider.slides"
        :key="index"
      >
        <div>
          <b-card class="home-category-card">
            <router-link :to="slide.link">
              <div class="card-img-top-box">
                <b-img
                  class="card-img-top"
                  :src="slide.media.url"
                  :alt="slide.title"
                />
              </div>
              <div class="card-body-custom text-center">
                <div v-html="slide.title" class="card-title"></div>
              </div>
            </router-link>
          </b-card>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "BrandSliderComponent",
  components: { VueSlickCarousel },
  computed: {
    BrandSlider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "lees_meer_slider_brands"
      );
    },
  },
  data() {
    return {};
  },
};
</script>