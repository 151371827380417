var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.BrandSlider.slides)?_c('div',{staticClass:"category_slider"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("read_more_about_slider"))+" ")]),(_vm.BrandSlider.slides.length)?_c('VueSlickCarousel',{attrs:{"arrows":true,"dots":false,"slidesToShow":5,"slidesToScroll":1,"autoplay":false,"center":false,"responsive":[
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      } ]}},_vm._l((_vm.BrandSlider.slides),function(slide,index){return _c('div',{key:index,staticClass:"cms-block-content"},[_c('div',[_c('b-card',{staticClass:"home-category-card"},[_c('router-link',{attrs:{"to":slide.link}},[_c('div',{staticClass:"card-img-top-box"},[_c('b-img',{staticClass:"card-img-top",attrs:{"src":slide.media.url,"alt":slide.title}})],1),_c('div',{staticClass:"card-body-custom text-center"},[_c('div',{staticClass:"card-title",domProps:{"innerHTML":_vm._s(slide.title)}})])])],1)],1)])}),0):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }