<template>
  <div class="brandsPage top-padding">
    <b-container>

      <!-- Breadcrumb -->
      <Breadcrumbs />

      <!-- Show Brand Lists -->
      <section class="brands-section">
        <b-container>
          <b-row>

            <!-- Sidebar -->
            <b-col lg="3" class="pb-30 pb-lg-25">
              <BrandsSidebarLinks :sidebarData="brands" />
            </b-col>

            <!-- Brands -->
            <b-col lg="9">
              <h1 class="page-heading category-heading main-category-title">
                {{ $t("brands") }}
              </h1>
              <b-row>
                <b-col
                  cols="6"
                  md="4"
                  v-for="(brandData, index) in brands"
                  :key="`bp` + index"
                >
                  <b-link
                    v-if="brandData.urlKey != null"
                    :to="{ name: 'brand-detail', params: { code: brandData.urlKey } }"
                    class="brand-card"
                  >
                    <div class="brand-logo">
                      <img
                        :src="brandData.logo"
                        :alt="brandData.title"
                        v-if="brandData.logo"
                      />
                    </div>
                    <span class="brand-name">{{ brandData.title }}</span>
                  </b-link>
                  <div class="brand-card" v-if="brandData.urlKey == null">
                    <div class="brand-logo">
                      <img
                        :src="brandData.logo"
                        :alt="brandData.title"
                        v-if="brandData.logo"
                      />
                    </div>
                    <span class="brand-name">{{ brandData.title }}</span>
                  </div>
                </b-col>
              </b-row>
              <div class="bottom-txt d-lg-block" v-if="getCmsData" v-html="getCmsData"></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </b-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import BrandsSidebarLinks from "@/esf_utrecht_bankxl/core/components/brands/BrandsSidebarLinks.vue";
import config from "@config";

export default {
  name: "Brands",
  components: {
    Breadcrumbs,
    BrandsSidebarLinks,
  },
  mounted() {
    const bcrumb = { current: this.$t("brands"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    this.$store.dispatch("product/loadBrandSlider");
  },

  computed: {
    brands() {
      return this.$store.getters["product/getBrandSlider"];
    },
    getCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]("merken")
        ?.content;
    },
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("brands"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>


