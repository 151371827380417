<template>
  <div
    v-if="renderComponent"
    class="sidebar-category-filter"
    :class="{ show: isActive }"
  >

    <div class="category">
      <div class="category-wrap">
        <div class="tab-mob-show">
          <h3 class="category-heading border-none pb-15">
            {{ $t("search_help") }}
            <span>
              <b-nav-item
                class="close-sidebar tab-mob-show"
                @click="closeSidebar"
              >
                <b-img alt :src="closeIcon" />
              </b-nav-item>
            </span>
          </h3>
        </div>
        
        <!-- Brand logo -->
        <img v-if="brandDetail && brandDetail.logo" :src="brandDetail.logo" alt="logo" class="brand-detail-logo" fluid />
        <h6 v-if="brandDetail && brandDetail.title" class="mt-1 mb-3">{{ brandDetail.title }}</h6>

        <h3 class="category-heading tab-mob-none mb-0">
          {{ $t("search_help") }}
        </h3>
        <div class="heading bold pt-3 pb-15">
          <button @click="resetFilter" class="filter-btn">
            {{ $t("clear_filter") }}
          </button>
        </div>
        <div
          class="filterCheck"
          v-for="prodFilter of productFilters"
          :key="prodFilter.attribute_code"
        >
          <!-- Exculde brands filter -->
          <div>
            <div class="price" v-if="prodFilter.attribute_code == 'price'">
              <div :class="prodFilter.attribute_code">
                <div class="check-list-hd-category-checkbox">
                  <h6
                    class="list-hd-category-checkbox"
                    v-b-toggle="prodFilter.attribute_code"
                  >
                    <span class="category-icon">
                      <font-awesome-icon icon="caret-down" />
                    </span>
                    <span>{{ $t(prodFilter.label) }}</span>
                  </h6>
                  <b-collapse visible :id="prodFilter.attribute_code">
                    <div class="range-slider">
                      <div class="range-values d-flex justify-content-between">
                        <span
                          ><input
                            ref="priceFilter"
                            type="number"
                            :min="priceFilterMin"
                            :max="priceFilterMax"
                            @input="debounceMin"
                            :value="priceRange[0]"
                        /></span>
                        <span
                          ><input
                            ref="priceFilter"
                            type="number"
                            :min="priceFilterMin"
                            :max="priceFilterMax"
                            @input="debounceMax"
                            :value="priceRange[1]"
                        /></span>
                      </div>
                      <ClientOnly>
                        <VueRangeSlider
                          :min="priceFilterMin"
                          :max="priceFilterMax"
                          :lazy="true"
                          :formatter="formatter"
                          v-model="priceRange"
                          :logarithmic="true"
                          tooltip="active"
                          :enableCross="false"
                          :enable-cross="false"
                          ref="rangeSlider"
                        >
                        </VueRangeSlider>
                      </ClientOnly>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>

            <div class="size" v-if="prodFilter.attribute_code != 'price'">
              <div class="check-list-hd-category-checkbox">
                <h6
                  class="list-hd-category-checkbox"
                  v-b-toggle="prodFilter.attribute_code"
                >
                  <span class="category-icon">
                    <font-awesome-icon icon="caret-down" />
                  </span>
                  <span>{{ prodFilter.label }}</span>
                </h6>
                <b-collapse visible :id="prodFilter.attribute_code">
                  <div
                    class="filterdata"
                    v-for="(brandsFilterItem, index) in readMore[
                      prodFilter.attribute_code
                    ]
                      ? prodFilter.options
                      : prodFilter.options.slice(0, 5)"
                    :key="index"
                  >
                    <label
                      :for="brandsFilterItem.value"
                      class="d-flex align-items-center"
                    >
                      <input
                        type="checkbox"
                        :key="brandsFilterItem.value"
                        :name="brandsFilterItem.label"
                        :id="brandsFilterItem.value"
                        :ref="brandsFilterItem.value"
                        :value="brandsFilterItem.value"
                        @click="
                          onChangeFilter($event, {
                            attribute_code: prodFilter.attribute_code,
                            type_label: prodFilter.label,
                            value: brandsFilterItem.value,
                            label: brandsFilterItem.label,
                          })
                        "
                      />
                      <span
                        class="fake-input"
                        :style="{
                          background:
                            brandsFilterItem.swatch_data &&
                            brandsFilterItem.swatch_data.value,
                          borderColor:
                            brandsFilterItem.swatch_data &&
                            brandsFilterItem.swatch_data != '#ffffff' &&
                            brandsFilterItem.swatch_data.value,
                        }"
                      ></span>
                      <span class="label-wrap">
                        <span class="fake-label">{{
                          brandsFilterItem.label
                        }}</span>
                        <span class="number-available"
                          >({{ brandsFilterItem.count }})</span
                        >
                      </span>
                    </label>
                  </div>

                  <div v-if="prodFilter.count > 5" class="show-more">
                    <b-link
                      v-if="readMore[prodFilter.attribute_code]"
                      @click="toggleReadMore(prodFilter.attribute_code)"
                      >{{ $t("less") }}</b-link
                    >
                    <b-link
                      v-else
                      @click="toggleReadMore(prodFilter.attribute_code)"
                    >
                      {{ $t("show") }} {{ prodFilter.count - 5 }} {{ $t("more") }}
                    </b-link>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <SidebarRatingComponent />
      </div>
      <button
        @click="closeSidebar"
        class="filter-btn show-more-results mob-tab-show"
      >
        {{ $t("look_at") }} {{ totalProducts }} {{ $t("result") }}
        <b-icon icon="chevron-right"></b-icon>
      </button>
    </div>
  </div>
</template>
<script>
import closeIcon from "@/esf_utrecht_bankxl/assets/images/close-icon.png";
import SidebarRatingComponent from "@/esf_utrecht_bankxl/core/components/products/category-product/SidebarRatingComponent";
import ClientOnly from "vue-client-only";
import VueRangeSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import { debounce } from "lodash";

export default {
  name: "ProductFilter",
  props: ["isActive"],
  inject: ["openSidebar", "closeSidebar"],
  
  components: {
    SidebarRatingComponent,
    ClientOnly,
    VueRangeSlider,
  },
  
  data() {
    return {
      closeIcon,
      show: true,
      sliderIsLoaded: false,
      bla: false,
      minAngle: 10,
      maxAngle: 30,
      filterExpanded: false,
      readMore: {},
      minRangeFilter: 0,
      priceRange: [0, 0],
      renderComponent: true,
    };
  },
  
  mounted() {
    // this.$store.dispatch("product/load");
    this.forceRerender();
    this.priceRange = this.priceFilter;
  },
  
  computed: {
    category() {
      return this.$store.getters["product/getCurrentCategory"];
    },
    filterItemsSelected() {
      return this.$store.getters["product/getActiveFilter"];
    },
    isPriceFilterActive() {
      return this.$store.getters["product/getIsPriceFilterActive"];
    },
    priceFilterMin() {
      return this.$store.getters["product/getPriceFilterMin"];
    },
    priceFilterMax() {
      return this.$store.getters["product/getPriceFilterMax"];
    },
    priceFilter() {
      return this.$store.getters["product/getPriceFilter"];
    },
    productFilters() {
      var productFilters = JSON.parse(
        JSON.stringify(this.$store.getters["product/getProductFilters"])
      );
      if (
        typeof productFilters === "object" &&
        !Array.isArray(productFilters)
      ) {
        return [];
      }
      productFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.sort((a, b) => {
            return a.label < b.label ? -1 : 1;
          });
        }
      });
      return productFilters;
    },
    totalProducts() {
      return this.$store.getters['product/getBrandProductsTotalCount'] || 0;
    },
    aggregations() {
      return this.$store.getters["product/getAggregations"];
    },
    brandDetail() {
      return this.$store.getters["product/getBrandDetailData"];
    }
  },

  methods: {
    forceRerender() {
      this.renderComponent = false;
      setTimeout(() => {
        this.renderComponent = true;
      }, 500);
    },

    onChangeFilter(event, filter) {
      if (typeof filter != "undefined" && typeof filter.value != "undefined") {
        if (event.target.checked == true) {
          this.$store.commit("product/setActiveFilter", filter);
        } else {
          this.$store.commit("product/removeActiveFilter", filter);
        }
      }
      this.updateUrlFilters();
    },
    
    debounceMin: debounce(function (event) {
      this.onPriceRangeMinChange(event);
    }, 500),
    debounceMax: debounce(function (event) {
      this.onPriceRangeMaxChange(event);
    }, 500),

    resetFilter() {
      Object.keys(this.$refs).forEach((el) => {
        if (
          this.$refs[el][0] &&
          this.$refs[el][0].type === "checkbox" &&
          this.$refs[el][0].checked === true
        ) {
          this.$refs[el][0].checked = false;
        }
      });

      this.$store.commit("product/resetPriceFilter");
      this.$store.commit("product/resetActiveFilter");
      this.$store.commit("product/resetCurrentPage");
      this.$store.dispatch("product/getProductByBrand");

      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });
    },

    toggleReadMore(attribute_code) {
      if (this.readMore[attribute_code]) {
        this.readMore = {
          ...this.readMore,
          [attribute_code]: false,
        };
      } else {
        this.readMore = {
          ...this.readMore,
          [attribute_code]: true,
        };
      }
    },

    addPriceFilters() {
      const newFilters = this.$store.getters["product/getProductFilters"];
      if (Array.isArray(newFilters)) {
        const priceFilter = newFilters.find(
          (f) => f.attribute_code === "price"
        );
        if (
          !this.priceProdFilter ||
          !Object.keys(this.priceProdFilter).length
        ) {
          this.priceProdFilter = { ...priceFilter };
        }
      }
    },
    updateUrlFilters() {
      const activeFilters = {};
      this.filterItemsSelected.forEach((val) => {
        if (activeFilters[val.attribute_code]) {
          activeFilters[val.attribute_code].push(val.value);
        } else {
          activeFilters[val.attribute_code] = [val.value];
        }
      });
    },
    onPriceRangeMinChange(event) {
      if (
        Number(event.target.value) <= this.priceRange[1] &&
        Number(event.target.value) >= Number(event.target.min)
      ) {
        this.priceRange[0] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },
    onPriceRangeMaxChange(event) {
      if (
        Number(event.target.value) >= this.priceRange[0] &&
        Number(event.target.value) <= Number(event.target.max)
      ) {
        this.priceRange[1] = Number(event.target.value);
        this.processPricePriceRangeChange(this.priceRange);
        this.$refs.rangeSlider[0].setValue(this.priceRange);
      }
    },

    // For price range filter
    processPricePriceRangeChange(newValue) {
      if (this.$refs.priceFilter) {
        const inputMin = this.$refs.priceFilter[0];
        if (inputMin && parseInt(newValue[0]) <= parseInt(newValue[1])) {
          this.$store.commit("product/setPriceFilter", [...newValue]);
        }
      }
    },

    findAttributeValue(key, value) {
      this.aggregations.forEach((filter) => {
        if (filter.attribute_code == key) {
          value.forEach((val, index) => {
            const foundValue = filter.options.find((el) => el.value == val);
            if (foundValue) {
              value[index] = foundValue.label;
            }
          });
        }
      });
      return value;
    },
  },

  updated() {
    if (this.filterItemsSelected && this.filterItemsSelected.length) {
      this.filterItemsSelected.forEach((filter) => {
        if (this.$refs[filter.value] && this.$refs[filter.value][0]) {
          this.$refs[filter.value][0].checked = true;
        }
      });
    }
  },

  watch: {
    priceFilter(val) {
      this.priceRange = JSON.parse(JSON.stringify(val));
      this.$forceUpdate();
    },

    priceRange(newValue) {
      if (
        !(
          newValue[0] === this.priceFilter[0] &&
          newValue[1] === this.priceFilter[1]
        )
      ) {
        this.processPricePriceRangeChange(newValue);
      }
    },
    "category.name"() {
      Object.keys(this.$refs).forEach((el) => {
        if (
          this.$refs[el][0] &&
          this.$refs[el][0].type === "checkbox" &&
          this.$refs[el][0].checked === true
        ) {
          this.$refs[el][0].checked = false;
        }
      });
      this.$store.commit("product/resetActiveFilter");
      this.$store.commit("product/resetCurrentPage");
    },
  },

  created() {
    this.min = 0;
    this.max = 250;
    this.enableCross = false;
    this.formatter = "€ {value}";
  },

  metaInfo() {
    let filtered =
      (this.filterItemsSelected && this.filterItemsSelected.length > 0) ||
      this.isPriceFilterActive;
    return {
      meta: [
        {
          name: "robots",
          content: filtered ? "noindex,nofollow" : "index,follow",
        },
      ],
    };
  },
};
</script>
