<template>
  <div class="brandPage top-padding">
    <b-container>

      <!-- Breadcrumb -->
      <Breadcrumbs />

      <div class="row product-item-row mt-2">

        <!-- Side Filters -->
        <div class="hiddenElement" ref="filterButton"></div>
        <div class="col-12 tab-mob-show" :class="stickyClass">
          <div
            class="pb-2 pt-2 filter-result text-center text-white bg-primary filter-btn-wrap"
          >
            <span class="filter-icon"
              ><font-awesome-icon icon="filter"
            /></span>
            <span class="filter-txt pl-2" @click="openSidebar"
              >{{ $t("search_help") }} {{ totalProducts }}
              {{ $t("result") }}</span
            >
          </div>
        </div>
        <ProductFilter v-if="this.brandDetail" class="col-lg-3" :isActive="isActive"  @close-sidebar="closeSidebar" />
        
        <!-- Products and Pagination -->
        <BrandPageContent :class="this.brandDetail ? 'col-lg-9' : 'col-12'" />
      </div>
    </b-container>
  </div>
</template>

<script>
import config from "@config";
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import ProductFilter from "@/esf_utrecht_bankxl/core/components/brands/ProductFilter.vue";
import BrandPageContent from "@/esf_utrecht_bankxl/core/components/brands/BrandPageContent.vue";

export default {
  name: "Brand",
  
  components: {
    Breadcrumbs,
    ProductFilter,
    BrandPageContent,
  },

  data() {
    return {
      isActive: false,
      stickyClass: "nonStickyButton",
    };
  },

  provide() {
    return {
      openSidebar: this.openSidebar,
      closeSidebar: this.closeSidebar,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    // get breadcrumb data
    const bcrumb = {
      current: this.currentbrand.title,
      routes: [
        {
          name: this.$t("brands"),
          route_link: "/merken",
        },
      ],
    };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  computed: {
    currentbrand() {
      return this.$store.getters["product/getBrandSliderByCode"](
        this.$route.params.code
      );
    },
    totalProducts() {
      return this.$store.getters['product/getBrandProductsTotalCount'] || 0;
    },

    brandDetail() {
      return this.$store.getters["product/getBrandDetailData"];
    },
  },

  methods: {
    openSidebar() {
      this.isActive = true;
    },
    closeSidebar() {
      this.isActive = false;
    },
    handleScroll() {
      var top = this.$refs.filterButton.getBoundingClientRect().top;
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (
        Math.abs(currentScrollPosition - this.lastScrollPosition) < 60 &&
        currentScrollPosition < this.lastScrollPosition
      ) {
        this.stickyClass = "top60";
      } else {
        this.stickyClass = "";
      }
      this.lastScrollPosition = currentScrollPosition;
      if (top <= 0) {
        this.stickyClass += " stickyButton";
      } else {
        this.stickyClass += " nonStickyButton";
      }
    },
  },

  metaInfo() {
    if (this.currentbrand) {
      return {
        meta: [
          { name: "title", content: this.currentbrand && this.currentbrand.title
            ? this.currentbrand.title
            : this.currentbrand && this.currentbrand.metaTitle
            ? this.currentbrand.metaTitle
            : "" },
          {
            name: "keywords",
            content:
              this.currentbrand && this.currentbrand.metaKeywords
                ? this.currentbrand.metaKeywords
                : "",
          },
          {
            name: "description",
            content:
              this.currentbrand && this.currentbrand.metaDescription
              ? this.currentbrand.metaDescription
              : this.currentbrand && this.currentbrand.shortDescription
              ? this.currentbrand.shortDescription.replace(/<[^>]*>/g, "")
              : this.currentbrand.description,
          },
          { property: "og:type", content: "currentbrand" },
          {
            property: "og:title",
            content:
              this.currentbrand && this.currentbrand.title
                ? this.currentbrand.title
                : this.currentbrand && this.currentbrand.metaTitle
                ? this.currentbrand.metaTitle
                : "",
          },
          {
            property: "og:image",
            content:
              this.currentbrand && this.currentbrand.logo
                ? this.currentbrand.logo
                : "",
          },
          {
            property: "og:url",
            href:
              config.app_url +
              (/\/$/.test(this.$route.fullPath)
                ? this.$route.fullPath
                : this.$route.fullPath.replace(/^\//, "") + "/"),
          },
          {
            property: "og:description",
            content:
             this.currentbrand && this.currentbrand.metaDescription
              ? this.currentbrand.metaDescription
              : this.currentbrand && this.currentbrand.shortDescription
              ? this.currentbrand.shortDescription.replace(/<[^>]*>/g, "")
              : this.currentbrand.description,
          },
        ],
        //test 3
        // if no subcomponents specify a metaInfo.title, this title will be used
        title:
          this.currentbrand && this.currentbrand.title
            ? this.currentbrand.title
            : this.currentbrand && this.currentbrand.metaTitle
            ? this.currentbrand.metaTitle
            : "",
        link: [
          {
            rel: "canonical",
            href:
              config.app_url +
              (/\/$/.test(this.$route.fullPath)
                ? this.$route.fullPath
                : this.$route.fullPath.replace(/^\//, "") + "/"),
          },
        ],
      };
    }
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
